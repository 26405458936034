@import '../../styles/index';

.base {
  border-radius: 0.375rem;
  background-color: $color-white--100;
  box-shadow: $shadow-job-apply;
  padding: 1.5rem 1rem 1rem;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @include media-from(xs) {
    align-items: center;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: $color-black--20;
    border: none;
    margin: 1rem 0;
  }
}

.headline {
  color: $color-black--100;
  font-size: $font-size-24;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 1rem;
  text-align: center;
}

.sub-text {
  color: $color-black--60;
  font-size: $font-size-14;
  line-height: 1.4;
  text-align: center;
  margin: 0 1.25rem;
}

.info-box {
  display: flex;
  justify-content: center;
  align-items: center;

  color: $color-black--100;
  font-size: $font-size-16;
  line-height: 1.75;

  span {
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 3.375rem;

      @include media-from(xs) {
        margin-right: 1.5rem;
      }
    }
  }
}

.icon {
  color: $color-black--40;
  margin-right: 0.5rem;
}
