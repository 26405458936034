@import '../../styles/index';

.base {
  // TODO: remove override styles !!!
  :global(* > [class~='Image-module-base']) {
    @include media-from(lg) {
      margin-left: -6.78125rem;
      max-width: 66.375rem;
      width: calc(100% + 13.5625rem) !important;

      img {
        width: 100% !important;
      }
    }
  }

  :global(.PressHeader-module-base-magnolia) {
    margin-bottom: 4rem !important;
  }

  :global(.RichText-module-base ul li::before) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 21 20'%3E%3Cpath d='M1.5 20.017a1.502 1.502 0 01-1.061-2.561l7.523-7.522L.589 2.56A1.5 1.5 0 112.709.438l8.436 8.434a1.504 1.504 0 010 2.121L2.56 19.578a1.495 1.495 0 01-1.061.44' fill='%23c7125c' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: 1rem;
    margin-left: -1.5rem;
    margin-top: 0.25rem;
    width: 1rem;
  }

  :global(.RichText-module-base ul li) {
    padding-left: 1.5rem;
  }

  :global(.Section-module-skew-bg-gray::before) {
    height: 300px;
    top: -300px;
  }
  @include media-from(lg) {
    :global(.ImageGallery-module-image-wrapper > figure > div) {
      height: 550px;
    }
  }

  :global(.Container-module-container.Container-module-children-space-small)
    > div:not(:last-child),
  :global(.Container-module-container.Container-module-children-space-small)
    > button:not(:last-child),
  :global(.Container-module-container.Container-module-children-space-small)
    > figure:not(:last-child),
  :global(.Container-module-container.Container-module-children-space-small)
    > header:not(:last-child) {
    @include media-to(md) {
      margin-bottom: 2rem;
    }
  }

  :global(.Quote-module-base) {
    margin-top: 2rem;
  }

  :global(.Container-module-container header[class^='Headline']) {
    margin-bottom: 0.75rem;
  }

  :global(.PressArticle-module-download) {
    display: flex;
    justify-content: space-between;
  }

  :global(.PressArticle-module-download-all) {
    color: $color-surrogate--100;
    display: flex;
    font-size: 1.125rem;
    font-weight: 500;

    div {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    svg {
      margin-top: 2px;
    }

    a,
    a:visited {
      color: $color-surrogate--100;
      margin-left: 0.5rem;
    }
    @include media-from(sm) {
      font-size: 1.25rem;
    }
  }

  :global(.Accordion-module-hr) {
    border-bottom: 1px solid #bcbdbc !important;
  }

  :global(.Accordion-module-base):not(:first-child) hr:first-child {
    display: block;
  }

  :global(.Accordion-module-headline-container) {
    padding: 2rem 0 1.5rem;
  }

  :global(.Accordion-module-description) {
    padding: 0 1rem 1.5rem 0;
  }
}
