@use 'sass:math';
@import '../../styles/index';

$border-radius-value: var(--section-border-radius, 2.5rem);
$skew-shift: 126px;
$responsive-skew-shift: min(
  calc(math.div($skew-shift, map-get($breakpoints, 'xl')) * 100vw),
  $skew-shift
);

@function withResponsiveSkewShift($value) {
  @return calc($value + $responsive-skew-shift);
}

.section {
  background-position: center;
  background-size: cover;
  margin-top: -0.5px; // workaround - fix reder browser bug of additional space between sections
  margin-inline: auto;
  max-width: $layout-max-width;
  position: relative;
  width: 100%;

  & > div {
    @include space-between-containers();
  }

  &.fullWidth {
    max-width: 100%;
  }

  &.has-aspect-ratio-fallback {
    padding-bottom: 70%;
  }

  &.has-border-radius {
    overflow: hidden;
  }

  &.all-rounded {
    border-radius: $border-radius-value;
  }

  &.top-rounded {
    border-top-left-radius: $border-radius-value;
    border-top-right-radius: $border-radius-value;
  }

  &.bottom-rounded {
    border-bottom-left-radius: $border-radius-value;
    border-bottom-right-radius: $border-radius-value;
  }

  &.parallax {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 60vh;
    overflow: hidden;
  }
}

.skewed-top,
.skewed-bottom {
  overflow: hidden;
  position: relative;
}

.skewed-top {
  clip-path: polygon(0 $responsive-skew-shift, 100% 0, 100% 100%, 0% 100%);
}

.skewed-bottom {
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - $responsive-skew-shift),
    0% 100%
  );
}

.skewed-top.skewed-bottom {
  clip-path: polygon(
    0 $responsive-skew-shift,
    100% 0,
    100% calc(100% - $responsive-skew-shift),
    0% 100%
  );
}

.key-visual {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50% !important;

  @include media-to(xs) {
    clip-path: none !important;
  }

  @include media-from(sm) {
    margin-bottom: 6.5rem;
  }

  & .children {
    position: static;
  }
}

.small-key-visual {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50% !important; // Added to ensure correct aspect ratio

  @include media-to(xs) {
    clip-path: none !important;
  }

  @include media-from(sm) {
    margin-bottom: 6.5rem;
    padding-bottom: 26% !important; // Added to ensure correct aspect ratio
  }
}

.wrapper {
  position: relative;
}

.video {
  height: 100%;
  left: 0;
  object-fit: cover;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 2s ease-in-out;
  width: 100%;

  &[data-vimeo-initialized],
  &[data-loaded] {
    opacity: 1;
    z-index: 1;
  }
}

.background {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;

  > div {
    height: 100%;
    width: 100%;

    > picture > img {
      height: 100%;
      max-height: none;
      object-fit: cover;
      object-position: center center;
    }
  }

  &::before,
  &::after {
    content: '';
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.gradient {
    &.has-background-image:not(.background-gradient-overlay) {
      &::before,
      &::after {
        opacity: 0.9;
      }
    }
  }

  &.gray {
    &::before,
    &::after {
      background: $color-violet--05;
    }
  }

  &.gradient,
  &.gray {
    &.background-gradient-overlay {
      &:before {
        display: block;
      }
    }

    &:not(.background-gradient-overlay) {
      &:after {
        display: block;
      }
    }
  }
}
.children {
  position: relative;
  z-index: 1;
}

.with-shadow {
  &::before {
    background-repeat: no-repeat;
    background-size: 100%;
    content: ' ';
    height: 100%;
    left: 2%;
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    width: 95%;

    @include media-from(sm) {
      left: 1.5%;
    }
  }
}

.has-padding {
  @include horizontal-padding-with-safe-area(
    $section-padding-horizontal-mobile
  );
}

.section:not(.large-padding):not(.large-padding-bottom) {
  --section-space: #{$space-between-sections};

  @include media-from(xs) {
    --section-space: #{$space-between-sections-xs};
  }

  @include media-from(sm) {
    --section-space: #{$space-between-sections-sm};
  }
}

.large-padding,
.large-padding-bottom {
  --section-space: #{$large-space-between-sections};

  @include media-from(xs) {
    --section-space: #{$large-space-between-sections-xs};
  }

  @include media-from(sm) {
    --section-space: #{$large-space-between-sections-sm};
  }
}

.section {
  &:not(.reset-top-space):not(.skewed-top):not(.key-visual) {
    padding-top: var(--section-space);
  }

  &.has-bottom-space:not(.skewed-bottom):not(.no-space-between) {
    padding-bottom: var(--section-space);
  }

  // workaround for supporting clipping images in two columns containers
  &.skewed-top:not(.reset-top-space) {
    margin-top: calc(-1 * $responsive-skew-shift); // negative margin
    padding-top: withResponsiveSkewShift(var(--section-space));
  }

  // workaround for supporting clipping images in two columns containers
  &.skewed-bottom:not(.no-space-between) {
    &:not([data-has-containers='true'])
      > [data-is-container='true']:not(:only-of-type):last-of-type,
    [data-has-containers='true']
      > [data-is-container='true']:not([data-no-section-space='true']) {
      &::after {
        @extend %empty-pseudo-block;

        padding-bottom: withResponsiveSkewShift(var(--section-space));
      }
    }
  }

  // prevent to double the space with the same color (padding-bottom + padding top)
  &:not(.force-double-padding-between) {
    &[data-bg-color='default'] + &[data-bg-color='default'],
    &[data-bg-color='white'] + &[data-bg-color='white'] {
      &:not(.with-shadow):not(:first-of-type):not(.no-space-between) {
        padding-top: 0;
      }
    }
  }

  // workaround for additional space for footer
  main > &:last-of-type {
    padding-bottom: calc(
      var(--section-space) + #{$responsive-skew-shift}
    ) !important;
  }

  [data-no-footer-space='true'] > main > &:last-of-type {
    @include media-to(sm) {
      padding-bottom: $space-between-containers !important;
    }

    @include media-from(sm) {
      padding-bottom: $space-between-containers-sm !important;
    }
  }
}
