@import '../../styles/index';

.call {
  margin-bottom: 0.5rem;
}

.now {
  margin-bottom: 0;
}

.phone {
  font-size: $font-size-30;
  font-weight: $font-weight-medium;
  line-height: 1.3;
  color: $color-surrogate--100;
  margin-bottom: 0.5rem;

  @include media-from(sm) {
    font-size: $font-size-44;
  }
}
