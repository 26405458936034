@import '../../styles/index';

$COLUMN_WIDTH: 10rem;

.base-wrapper {
  max-width: calc($COLUMN_WIDTH * 8);
  overflow-x: scroll;
  position: relative;
}

.base {
  display: grid;
  grid-template-columns: repeat(8, $COLUMN_WIDTH);
  grid-template-rows: repeat(7, 1fr);
  grid-template-areas:
    '. mo di mi do fr sa so'
    'paul paul-shift-first paul-shift-first paul-shift-second paul-shift-second . paul-shift-third .'
    'anna anna-shift-first anna-shift-first anna-shift-second anna-shift-second anna-shift-third anna-shift-third anna-shift-third'
    'jan jan-shift-first jan-shift-first . jan-shift-second . . .'
    'leon . leon-shift-first . . leon-shift-second leon-shift-second leon-shift-second'
    'matthias matthias-shift-first matthias-shift-first matthias-shift-first matthias-shift-first matthias-shift-first matthias-shift-first matthias-shift-first'
    'christina . christina-shift-first christina-shift-second christina-shift-second christina-shift-third christina-shift-third christina-shift-third';
}

.pill-wrapper {
  background-color: $color-surrogate--10;
  padding: 0.75rem;
  position: relative;
}

.pill {
  padding: 0.75rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  position: relative;
  transition: transform $duration-default;
  font-weight: $font-weight-medium;
  font-size: $font-size-14;
  white-space: nowrap;
  z-index: $index-container;
  user-select: none;

  &:hover {
    transform: translateY(-0.25rem);
  }
}

.pill-icon {
  padding-right: 0.5rem;
}

.day {
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  color: $color-surrogate--60;
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
}

.person {
  padding: 1rem 0;
  color: $color-black--100;
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.person-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.625rem;
}

.person-image {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.shift-box {
  padding: 0.75rem;
}

.white {
  background-color: white;
}

.surrogate {
  background-color: $color-surrogate--03;
}

.back-shift {
  color: $color-red--100;
}

.night-shift {
  color: $color-violet--100;
}

.early-shift {
  color: $color-orange--100;
}

.disposal-shift {
  color: $color-green--100;
}

.rest-day {
  color: $color-blue--100;
}

.border-top-left {
  border-top-left-radius: 0.5rem;
}

.border-top-right {
  border-top-right-radius: 0.5rem;
}

.border-bottom-left {
  border-bottom-left-radius: 0.5rem;
}

.border-bottom-right {
  border-bottom-right-radius: 0.5rem;
}

.vertical-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & > span {
    @for $i from 2 through 7 {
      &:nth-child(#{$i - 1}) {
        position: absolute;
        width: 1px;
        background-color: $color-surrogate--10;
        top: 0;
        bottom: 0;
        z-index: 1;
        left: #{$COLUMN_WIDTH * $i};
      }
    }
  }
}

.bottom-line {
  border-bottom: 1px solid $color-surrogate--10;
}

// ignore area assignments
.paul-shift-first {
  grid-area: paul-shift-first;
}
.paul-shift-second {
  grid-area: paul-shift-second;
}
.paul-shift-third {
  grid-area: paul-shift-third;
}
.anna-shift-first {
  grid-area: anna-shift-first;
}
.anna-shift-second {
  grid-area: anna-shift-second;
}
.anna-shift-third {
  grid-area: anna-shift-third;
}
.jan-shift-first {
  grid-area: jan-shift-first;
}
.jan-shift-second {
  grid-area: jan-shift-second;
}
.leon-shift-first {
  grid-area: leon-shift-first;
}
.leon-shift-second {
  grid-area: leon-shift-second;
}
.christina-shift-first {
  grid-area: christina-shift-first;
}
.christina-shift-second {
  grid-area: christina-shift-second;
}
.christina-shift-third {
  grid-area: christina-shift-third;
}
.matthias-shift-first {
  grid-area: matthias-shift-first;
}
.mo {
  grid-area: mo;
}
.di {
  grid-area: di;
}
.mi {
  grid-area: mi;
}
.do {
  grid-area: do;
}
.fr {
  grid-area: fr;
}
.sa {
  grid-area: sa;
}
.so {
  grid-area: so;
}
.paul {
  grid-area: paul;
}
.anna {
  grid-area: anna;
}
.jan {
  grid-area: jan;
}
.leon {
  grid-area: leon;
}
.matthias {
  grid-area: matthias;
}
.christina {
  grid-area: christina;
}
