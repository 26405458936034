@import '../../styles/index';

.base {
  margin: 0;
  border-radius: 4px;
  border: 1px solid $color-surrogate--20;
  background-color: $color-surrogate--03;

  margin-bottom: 1rem;
  padding: 1.5rem 1rem;

  @include media-from(sm) {
    margin-bottom: 2rem;
    padding: 2rem 1.5rem;
  }
}

.text {
  color: $color-black--100;
  font-size: $font-size-16;
  line-height: 1.5;
  font-weight: $font-weight-light;
  text-align: center;

  @include media-from(sm) {
    font-size: $font-size-18;
  }

  p {
    margin-top: 0;
  }

  // overrides for Magnolia
  div {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.with-out-rich-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
