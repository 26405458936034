@import '../../../styles/index';

.base {
  align-items: center;
  background-color: $color-white--100;
  display: flex;
  height: 4.5rem;
  padding: 0 1rem;
  position: relative;
  transition: backdrop-filter $duration-default,
    background-color $duration-default;
  z-index: 2;

  &.is-inverted {
    background-color: transparent;
    color: $color-white--100;
  }

  &.is-translucent {
    backdrop-filter: saturate(180%) blur(1.25rem);
    background-color: rgba($color-white--100, 0.8);
  }

  @include media-from(lg) {
    [data-collapse-until-xl="false"] & {
      height: 6rem;
      padding: 0 1.25rem;
    }
  }

  @include media-from(xl) {
    [data-collapse-until-xl="true"] & {
      height: 6rem;
      padding: 0 1.25rem;
    }
  }
}

.logo {
  display: flex;
  opacity: 1;
  pointer-events: all;
  transition: opacity $duration-default $duration-default;
}

.items {
  align-items: center;
  display: none;
  gap: 2rem;
  margin-left: 2.5rem;
  opacity: 1;
  pointer-events: all;
  transition: opacity $duration-default $duration-default;

  @include media-from(lg) {
    [data-collapse-until-xl="false"] & {
      display: flex;
    }
  }

  @include media-from(xl) {
    [data-collapse-until-xl="true"] & {
      display: flex;
    }
  }
}

.item {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: flex;
  font-size: $font-size-20;
  font-weight: $font-weight-medium;
  gap: 0.125rem;
  padding: 0;

  &:hover,
  &.is-active {
    color: $color-surrogate--100;
  }

  .is-inverted & {
    transition: color $duration-short;

    &.is-active {
      color: $color-white--100;
    }

    &:hover {
      color: rgba($color-white--100, 0.5);
    }
  }

  &.is-active &-chevron {
    transform: scaleY(-1);
  }

  &-chevron {
    transition: transform $duration-default;
  }

  &-icon {
    margin-right: 0.5rem;
  }
}

.addons {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-left: auto;

  @include media-from(lg) {
    [data-collapse-until-xl="false"] & {
      gap: 1.5rem;
    }
  }

  @include media-from(xl) {
    [data-collapse-until-xl="true"] & {
      gap: 1.5rem;
    }
  }
}

.addon-button {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
  justify-content: center;
  opacity: 1;
  pointer-events: all;
  transition: color $duration-short 0s,
    opacity $duration-default $duration-default;

  &.is-active {
    color: $color-surrogate--100;
  }
}

.addon-button-icon {
  align-items: center;
  display: flex;
  height: 1.25rem;
  margin-bottom: 0.125rem;
  justify-content: center;
  width: 1.25rem;
}

.menu {
  background-color: $color-white--100;
  border-radius: 6px;
  box-shadow: $shadow-small;
  color: $color-black--100;
  display: grid;
  gap: 0 1rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 0 2.5rem;
  opacity: 0;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  top: calc(4.5rem + 1.25rem + var(--offset));
  transform: scale(0.975);
  transition: opacity $duration-long, transform $duration-long;
  width: calc(100% - 5rem);
  z-index: 1;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }

  .is-inverted & {
    margin: 0 1.25rem;
    width: calc(100% - 2.5rem);
  }

  @include media-from(lg) {
    [data-collapse-until-xl="false"] & {
      top: calc(6rem + 1.25rem + var(--offset));
    }
  }

  @include media-from(xl) {
    [data-collapse-until-xl="true"] & {
      top: calc(6rem + 1.25rem + var(--offset));
    }
  }
}

.menu-host {
  clip-path: polygon(0 0, 100% 0, 100% 100vh, 0 100vh);
  position: relative;
}

.mobile-menu-toggle {
  display: flex;
  padding: 0;

  @include media-from(lg) {
    [data-collapse-until-xl="false"] & {
      display: none;
    }
  }

  @include media-from(xl) {
    [data-collapse-until-xl="true"] & {
      display: none;
    }
  }
}

.mobile-menu-host {
  background-color: $color-white--100;
  display: block;
  height: calc(100vh - var(--offset, 7.625rem));
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  transition: opacity $duration-default;
  width: 100%;
  z-index: 1;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  @include media-from(lg) {
    [data-collapse-until-xl="false"] & {
      display: none;
    }
  }

  @include media-from(xl) {
    [data-collapse-until-xl="true"] & {
      display: none;
    }
  }

  @supports (height: 100dvh) {
    height: calc(100dvh - var(--offset, 7.625rem));
  }
}

.mobile-menu-page {
  left: 0;
  margin: 0;
  max-height: calc(100vh - var(--offset, 7.625rem));
  opacity: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  transition: opacity $duration-default, transform $duration-default;
  width: 100%;

  &.is-root {
    transform: translateX(-100%);
  }

  &.is-active {
    opacity: 1;
    transform: translateX(0);
  }

  @supports (height: 100dvh) {
    height: calc(100dvh - var(--offset, 7.625rem));
  }
}

.mobile-menu-audiences {
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  padding: 1rem;
}

.mobile-menu-audience-link {
  color: currentColor;
  font-size: $font-size-16;
  position: relative;
  white-space: nowrap;

  &.is-active {
    font-weight: $font-weight-bold;

    &::after {
      background-color: $color-surrogate--100;
      bottom: -0.125rem;
      content: '';
      height: 0.125rem;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
}

.mobile-menu-items {
  padding: 0 1rem;

  &:last-child {
    padding-bottom: 3.5rem;
  }

  & > * + * {
    border-top: 1px solid $color-black--10;
  }
}

.mobile-menu-back-item {
  gap: 1rem;
  padding: 1rem;
}

.mobile-menu-back-item,
.mobile-menu-item {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: flex;
  font-size: $font-size-20;
  font-weight: $font-weight-medium;
  height: 4rem;
  width: 100%;
}

.mobile-menu-item {
  padding: 0;

  & + & {
    border-top: 1px solid $color-black--10;
  }
}

.mobile-menu-item-chevron {
  margin-left: auto;
}

.mobile-menu-item-icon {
  margin-right: 0.5rem;
}

.mobile-menu-links {
  margin-top: 0.5rem;
}

.mobile-menu-link {
  align-items: center;
  color: $color-surrogate--100;
  display: flex;
  font-size: $font-size-16;
  height: 2.5rem;
  padding: 0.5rem 1rem;
}

.container {
  display: contents;
  transition: backdrop-filter $duration-default,
    background-color $duration-default;

  &.is-full-width {
    background-color: $color-white--100;
    display: block;

    &.is-inverted {
      background-color: transparent;
      color: $color-white--100;
    }

    &.is-translucent {
      backdrop-filter: saturate(180%) blur(1.25rem);
      background-color: rgba($color-white--100, 0.8);
    }

    .base {
      background-color: transparent;
      margin: 0 auto;
      max-width: 1440px;
    }

    .menu-host {
      margin: 0 auto;
      max-width: 1440px;
    }
  }
}

.has-career-layout {
  .addons > *:not(.mobile-menu-toggle) {
    display: none;
  }

  @include media-from(lg) {
    .addons {
      display: none;
    }

    .items {
      margin-left: auto;
    }
  }
}

.is-hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity $duration-default 0s;
}
