@import '../../styles/index';

.base {
  background-color: white;
  padding: 1.5rem;

  @include media-to(sm) {
    padding: 1rem;
  }

  ul {
    padding-left: 20px;
    font-size: $font-size-16;
    color: $color-black--60;
  }
}
.box {
  display: flex;
  justify-content: space-between;
  position: relative;

  &.centered {
    align-items: center;
  }
}

.info-box {
  display: flex;

  & > *:not(:last-child) {
    margin-right: 26px;
  }
}

.labels {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  max-width: 70%;
  justify-content: center;

  @include media-from(sm) {
    max-width: 404px;
  }
}

.children {
  margin-top: 1.25rem;
}

.label {
  font-size: $font-size-18;
  font-weight: $font-weight-medium;

  &.default {
    color: $color-black--100;
  }

  &.green {
    color: $color-green--100;
  }

  &.linear-gradient {
    background-image: $gradient-enercity;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @include media-to(sm) {
    font-size: $font-size-16;
  }
}

.sublabel {
  font-size: $font-size-16;
  color: $color-black--60;
  max-width: 375px;
}

.price {
  font-size: $font-size-18;
  font-weight: $font-weight-regular;

  @include media-to(sm) {
    font-size: $font-size-16;
    position: absolute;
    right: 1rem;
  }
}

.icon {
  margin: 0.5rem 0 0 0;
  width: 32px;
  height: 32px;

  &.gray {
    color: $color-black--60;
  }

  &.green {
    color: $color-green--100;
  }

  &.surrogate {
    color: $color-surrogate--100;
  }

  @include media-to(sm) {
    display: none;
  }
}

.centered {
  .icon {
    margin-top: 0;
  }
}

.headline {
  margin-top: 0;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 500;
}
