@import '../../styles/index';

.base {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: $color-white--100;
}

.header {
  width: 100%;
  padding: 1.6875rem 2rem;
}

.action-component {
  padding: 0 1.25rem;
  max-width: 45rem;
}
