@import '../../styles/index';

.base {
  margin-bottom: 1.25rem;

  @include media-from(sm) {
    margin-bottom: 1.75rem;
  }

  &:last-of-type {
    margin-bottom: 1.5rem;
  }

  p {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
}

.headline {
  margin-bottom: 1rem;
  color: $color-black--100;
  font-size: $font-size-22;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5;
}

.children-text {
  margin-bottom: 0.5rem;
}
