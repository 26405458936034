@import '../../styles/index';

.base {
  max-width: 640px;
  margin: 0 auto;
  box-shadow: $shadow-configurator-light;
  border-radius: $border-radius-default;

  & > *:first-child {
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
  }

  & > *:last-child {
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
  }

  & > *:not(:last-child) {
    border-bottom: solid $color-black--20;
    border-width: 1.5px;
  }
}
