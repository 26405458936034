@import '../../../styles/index';

.base {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  opacity: 1;
  padding: 0;
  pointer-events: all;
  transition: opacity $duration-default $duration-default;

  &:hover {
    color: $color-surrogate--100;
  }

  &.is-inverted {
    transition: color $duration-short 0s,
      opacity $duration-default $duration-default;

    &:hover {
      color: rgba($color-white--100, 0.5);
    }
  }

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    transition: opacity $duration-default 0s;
  }

  @include media-from(lg) {
    flex-direction: row;
    gap: 0.5rem;
  }
}

.icon {
  flex-shrink: 0;

  &-badge {
    bottom: -0.125rem;
    display: flex;
    position: absolute;
    right: -0.25rem;
  }

  &-container {
    display: flex;
    flex-shrink: 0;
    position: relative;
  }
}

.title {
  font-size: $font-size-14;
  font-weight: $font-weight-medium;
  line-height: 1;

  @include media-from(lg) {
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
  }
}
