@import '../../../styles/index';

.base {
  position: relative;
  background-color: $color-yellow--100;
  color: $color-white--100;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  text-align: center;
  padding: 0.7rem 0;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -14px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid $color-yellow--100;
    transform: translateX(-50%);
  }

  @include media-from(sm) {
    display: none;
  }
}
