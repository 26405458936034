@import '../../styles/index';

.base {
  aspect-ratio: 1 / 1;
  display: flex;
  height: auto;
  justify-content: stretch;
  justify-self: stretch;
  max-width: 100%;
  perspective: 1000px;
  position: relative;
  transform-style: preserve-3d;
  width: 25.6875rem;
}

.headline {
  font-size: clamp(1.5rem, 6.1vw, 1.875rem);
  font-weight: $font-weight-bold;
  line-height: 1.33;
  margin: 0;
  padding: 0;
  text-align: center;
}

.checkbox {
  position: absolute;
  visibility: hidden;
}

.front,
.back {
  align-items: center;
  align-self: stretch;
  backface-visibility: hidden;
  cursor: pointer;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  font-size: clamp(1rem, 4.1vw, 1.25rem);
  font-weight: $font-weight-regular;
  height: 100%;
  justify-content: center;
  left: 0;
  line-height: 1.5;
  padding: 2rem;
  position: absolute;
  top: 0;
  transition: transform 0.4s ease-in-out;
  user-select: none;
  width: 100%;

  @include media-from(sm) {
    font-size: 1.25rem;
  }

  > *:not(.action-icon) {
    width: 100%;
  }

  p,
  ul {
    margin: 0;
  }

  ul {
    list-style: none;
    text-align: left;

    &,
    li {
      margin: 0;
      padding: 0;
    }

    li {
      &:before {
        background-image: url('data:image/svg+xml,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="Icon Color" fill-rule="evenodd" clip-rule="evenodd" d="M10.1641 20.6987C9.86637 20.6987 9.57987 20.581 9.36837 20.3695L3.82963 14.83C3.39012 14.3905 3.39012 13.6787 3.82963 13.2392C4.26913 12.7997 4.98087 12.7997 5.42037 13.2392L10.0779 17.8967L20.2336 5.41524C20.6259 4.93299 21.3346 4.86024 21.8169 5.25249C22.2984 5.64474 22.3711 6.35274 21.9789 6.83499L11.0371 20.2832C10.8361 20.5307 10.5391 20.6815 10.2219 20.6972C10.2024 20.698 10.1829 20.6987 10.1641 20.6987Z" fill="white"/></g></svg>');
        background-repeat: no-repeat;
        content: '';
        display: block;
        float: left;
        height: 1.5rem;
        padding-right: 0.5rem;
        width: 1.5rem;
      }
    }
  }
}

.action-icon {
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
}

.icon {
  color: $color-surrogate--100;
  height: 5rem;
  width: 5rem;
}

.front {
  background-color: $color-white--100;
  box-shadow:
    0px 0px 2px 0px rgba(0, 0, 0, 0.1),
    0px 0px 27px 0px rgba(0, 0, 0, 0.12);
  color: $color-black--100;
  gap: 1.5rem;
  text-align: center;
  z-index: 1;

  .action-icon {
    color: $color-surrogate--100;
  }

  .checkbox:checked ~ & {
    transform: rotateY(calc(180deg - 0.1deg)); /* fix safari bug */
  }
}

.back {
  background-color: $color-surrogate--100;
  color: $color-white--100;
  gap: 0.75rem;
  transform: rotateY(-180deg);
  z-index: 0;

  a,
  p > a {
    color: inherit;
    display: inline-block;
    text-decoration: underline;
    transition: scale $duration-short;

    &:hover {
      scale: 1.025;
    }
  }

  .action-icon {
    color: $color-white--100;
  }

  .checkbox:checked ~ & {
    transform: rotateY(0deg);
  }
}
