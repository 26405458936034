@import '../../styles/index';

$vertical-video-breakpoint: 540px;

.base {
  aspect-ratio: 16 / 9;
  display: flex;
  margin-inline: auto;
  position: relative;

  @media (max-width: $vertical-video-breakpoint) {
    aspect-ratio: 9 / 16;
  }

  @include media-from(lg) {
    margin-inline: -1.25rem;
  }
}

.video {
  border: 0;
  display: flex;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.motto {
  aspect-ratio: 290 / 140;
  bottom: 0;
  display: block;
  height: clamp(80px, 9.7222222222vw, 140px);
  left: clamp(20px, 5vw, 80px);
  position: absolute;
}

.poster {
  display: none;

  @media (min-width: $vertical-video-breakpoint) {
    display: block;
  }

  &-mobile {
    display: block;

    @media (min-width: $vertical-video-breakpoint) {
      display: none;
    }
  }
}

.logo {
  aspect-ratio: 284 / 105;
  color: $color-white--100;
  display: block;
  margin-left: clamp(20px, 6.6666666667%, 40px);
  margin-top: clamp(20px, 6.6666666667%, 25px);
  position: absolute;
  width: clamp(100px, 38%, 190px);
  z-index: 1;
}

.logo-wrapper {
  aspect-ratio: 500 / 380;
  display: block;
  position: absolute;
  top: 0;
  width: clamp(300px, 34.7222222222vw, 500px);

  &::before {
    background: url('./assets/logo-glow-bg.webp') no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
