@use 'sass:math';
@import '../../styles/index';

$base-skew: 124px;
$skew-height: min(
  $base-skew,
  calc(#{math.div($base-skew, map-get($breakpoints, xl))} * 100vw)
);

.base {
  content: '';
  position: relative;
  user-select: none;

  a {
    color: inherit;
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-inline: auto;
  max-width: $layout-max-width;
  position: relative;
  z-index: 2;

  @include media-from(sm) {
    align-items: center;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-between;
  }
}

.copyright {
  white-space: nowrap;
}

.foundation {
  align-items: center;
  background-color: $color-white--100;
  color: $color-black--60;
  display: flex;
  flex-direction: column;
  font-size: $font-size-16;
  gap: 0.5rem;
  margin-inline: auto;
  max-width: $layout-max-width;
  padding: 1.5rem;

  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    justify-content: center;
  }

  @include media-from(sm) {
    --foundation-padding: 2rem;

    flex-direction: row;
    gap: 2rem;
    justify-content: space-between;
    padding: var(--foundation-padding)
      calc(var(--foundation-padding) + env(safe-area-inset-right))
      var(--foundation-padding)
      calc(var(--foundation-padding) + env(safe-area-inset-left));

    .links {
      gap: 0.5rem 3.5rem;
    }
  }

  @include media-from(md) {
    --foundation-padding: 4rem;

    .links {
      justify-content: flex-end;
    }
  }
}

.link-directory .links a,
:not(.link-directory-group) > a.link-directory-title,
.link-row .links a,
.logo-area .links a {
  transition: opacity $duration-short;

  &:hover {
    opacity: 0.6;
  }
}

.link-directory {
  display: grid;
  order: -1;
  width: 100%;

  &.is-masonry .link-directory-group {
    break-inside: avoid;
  }

  @include media-from(sm) {
    gap: 3rem 1rem;
    grid-template-columns: repeat(2, 1fr);
    max-width: 45rem;
    order: unset;

    &.is-masonry {
      column-count: 2;
      column-gap: 1rem;
      display: block;
      margin-bottom: -2rem;

      .link-directory-group {
        padding-bottom: 2rem;
      }
    }
  }

  @include media-from(md) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 55rem;

    &.is-masonry {
      column-count: 3;
    }
  }
}

.link-directory-group {
  border-bottom: 1px solid rgba($color-white--100, 0.4);

  a {
    display: flex;
  }

  .links {
    line-height: 1.75;
  }

  @include media-from(sm) {
    border-bottom: none;
  }
}

.link-directory-title {
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  font-weight: 500;
  justify-content: space-between;
  margin: 0;
  padding: 1.25rem 0;
  width: 100%;

  :not(.link-directory-group) > & {
    border-bottom: 1px solid rgba($color-white--100, 0.4);
  }

  @include media-from(sm) {
    cursor: default;
    display: block;
    padding: 0;
    width: unset;

    :not(.link-directory-group) > & {
      border: none;
      cursor: pointer;
    }
  }
}

.link-row {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;

  .divider {
    display: none;
  }

  .links {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.125rem;
    gap: 1rem;
  }

  @include media-from(sm) {
    flex-direction: row;
    gap: 2rem;

    .divider {
      background-color: $color-black--10;
      display: block;
      height: 1.6875rem;
      width: 1px;
    }

    .links {
      align-items: center;
    }

    .text-links {
      gap: 2rem;
    }
  }
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
}

// This must stay underneath .links, otherwise !important is needed
.link-directory-title + * {
  @include media-from(sm) {
    margin-top: 0.5rem;
  }
}

.logo-area {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .links {
    align-items: center;
    display: flex;
    gap: 1rem;
  }

  @include media-from(sm) {
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;
  }
}

.logo-link {
  display: flex;
}

.social-link {
  align-items: center;
  display: flex;
  justify-content: center;

  &.square {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.structure {
  background: $gradient-enercity;
  clip-path: polygon(0 $skew-height, 100% 0, 100% 100%, 0 100%);
  color: $color-white--100;
  margin-top: calc($skew-height * -1);
  padding: calc($skew-height + 1.5rem) 1.5rem 2rem;
  position: relative;

  &.no-negative-margin {
    margin-top: 0;
  }

  @include media-from(sm) {
    padding: calc($skew-height + 3rem) 3rem 4rem;
  }
}
