@import '../../styles/index';

.base {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.is-overlay {
  opacity: 0.9;
}

.blue {
  background-color: $color-blue--05;
}

.green {
  background-color: $color-green--05;
}

.orange {
  background-color: $color-orange--05;
}

.red {
  background-color: $color-red--05;
}

.surrogate {
  background-color: $color-surrogate--100;
}

.surrogate-light {
  background-color: $color-surrogate--05;
}

.turquoise {
  background-color: $color-turquoise--05;
}

.violet {
  background-color: $color-violet--05;
}

.white {
  background-color: $color-white--100;
}

.yellow {
  background-color: $color-yellow--05;
}
