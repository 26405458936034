@import '../../styles/index';

.list {
  margin: 0;
  padding: 0;
}

.border-between > *:not(:last-of-type) {
  border-bottom: 1px solid $color-black--20;
}

.alternate-background > :nth-child(odd) {
  background: $color-black--10;
}
