@import '../../../styles/index';

$default-color: $color-green--20;

.base {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 50%;
    height: 1px;
    background-color: var(--accent--20, $default-color);
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 50%;
    height: 1px;
    background-color: var(--accent--20, $default-color);
  }
}

.text {
  color: $color-black--100;
  font-size: 1rem;
  font-weight: $font-weight-light;
  line-height: 20px;
  text-align: center;
  padding: 0 0.75rem;
  background-color: $color-white--100;
  z-index: 1;

  @include media-from(sm) {
    padding: 0 1.25rem;
    font-size: $font-size-18;
  }
}
