@use 'sass:math';
@import '../../styles/index';

$skew-shift: 126px;
$responsive-skew-shift: min(
  calc(math.div($skew-shift, map-get($breakpoints, 'xl')) * 100vw),
  $skew-shift
);

.base {
  background-color: $color-white--100;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - $responsive-skew-shift),
    0% 100%
  );
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;

  @include media-from(lg) {
    bottom: 0;
    padding-bottom: 0 !important;
  }

  @include media-to(xs) {
    clip-path: none !important;
  }
}

.image {
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity $duration-long $duration-long;
  width: 100%;

  &.is-active {
    opacity: 1;
    transition: opacity $duration-long;
  }

  &:last-of-type {
    transition: opacity $duration-long $duration-long, z-index 0s 1s;

    &.is-active {
      transition: opacity $duration-long $duration-long, z-index 0s 0s;
      z-index: $index-negative;
    }
  }
}
