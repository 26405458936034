@import '../../styles/index';

.base {
  margin: 0;
}

.background-wrapper {
  position: relative;

  @include media-from(lg) {
    margin: 0 -1rem;
  }
}

.background-image {
  height: auto;

  @include media-from(sm) {
    top: 3rem;
  }
}

.regio-first-fact-container {
  margin: 6rem auto;

  @include media-from(sm) {
    margin: 10rem auto;
  }
}

.regio-second-fact-container {
  margin: 5.5rem auto;
}

.regio-teaser-container {
  margin-bottom: 6rem;

  @include media-from(sm) {
    margin-bottom: 11rem;
  }
}

.regio-comparison-tips-container {
  > p {
    font-size: $font-size-16;
    font-weight: $font-weight-light;
    margin: 0;

    @include media-from(sm) {
      font-size: $font-size-22;
    }
  }
}

.headline {
  > div {
    max-width: 62ch;
  }
}

.tab-title {
  text-align: left;

  .consumption {
    display: block;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-top: 0.25rem;

    @include media-from(sm) {
      font-size: $font-size-16;
      font-weight: $font-weight-medium;
    }
  }
}
