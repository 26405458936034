@import '../../styles/index';

.base {
  display: grid;
  grid-template-columns: auto 1fr;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 0 27px 0 rgba(0, 0, 0, 0.12);

  @include media-from(md) {
    gap: 1.5rem;
  }

  &[data-color='orange'] {
    color: $color-orange--100;
    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(64%) sepia(59%) saturate(6226%) hue-rotate(6deg)
        brightness(97%) contrast(102%);
    }
  }

  &[data-color='cyan'] {
    color: $color-turquoise--100;
    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(46%) sepia(47%) saturate(5189%) hue-rotate(155deg)
        brightness(92%) contrast(101%);
    }
  }

  &[data-color='purple'] {
    color: $color-violet--100;
    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(9%) sepia(91%) saturate(5749%) hue-rotate(296deg)
        brightness(102%) contrast(108%);
    }
  }

  &[data-color='red'] {
    color: $color-red--100;
    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(17%) sepia(99%) saturate(5613%) hue-rotate(357deg)
        brightness(91%) contrast(121%);
    }
  }

  &[data-color='surrogate'] {
    color: $color-surrogate--100;

    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(19%) sepia(55%) saturate(3670%) hue-rotate(317deg)
        brightness(97%) contrast(108%);
    }
  }

  &[data-color='yellow'] {
    color: $color-yellow--100;
    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(89%) sepia(28%) saturate(4784%) hue-rotate(357deg)
        brightness(100%) contrast(106%);
    }
  }

  &[data-color='green'] {
    color: $color-green--100;
    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(57%) sepia(67%) saturate(517%) hue-rotate(52deg)
        brightness(95%) contrast(81%);
    }
  }

  &[data-color='blue'] {
    color: $color-blue--100;
    .icon {
      // generated with https://codepen.io/sosuke/pen/Pjoqqp
      filter: invert(17%) sepia(89%) saturate(2477%) hue-rotate(215deg)
        brightness(87%) contrast(92%);
    }
  }
}

.icon {
  display: none;

  @include media-from(md) {
    display: flex;
    width: 6rem;
  }
}

.img {
  width: 5.5rem;

  @include media-from(md) {
    width: 100%;
  }
}

.content {
  padding: 24px;

  @include media-from(md) {
    padding: 32px 32px 32px 0;
  }
}

.headline {
  margin: 0;
  font-size: $font-size-22;
  line-height: 1.75;

  @include media-from(md) {
    font-size: $font-size-24;
    line-height: 1.3;
  }
}

.text {
  color: black;
  font-size: $font-size-18;
  line-height: 1.75;
  margin: 0.5rem 0 0 0;
  font-weight: $font-weight-light;

  @include media-from(md) {
    font-size: $font-size-20;
    line-height: 1.6;
  }
}

.link {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: $font-size-18;
  line-height: 1.5;
  font-weight: $font-weight-medium;
  margin-top: 0.75rem;

  @include media-from(md) {
    font-size: $font-size-20;
    margin-top: 1rem;
  }
}

.arrow {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}
