@import '../../styles/index';

.base {
  display: flex;
  height: 3rem;
  width: 3rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;

  @include media-to(md) {
    color: $color-black--20;
    transition: color 0.6s;
    &.is-active {
      color: $color-surrogate--100;
    }
  }
  @include media-from(md) {
    height: 4rem;
    width: 4rem;
    display: flex;
  }
}

.pulse,
.inner-pulse {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  border-radius: 50%;

  display: none;
  z-index: 0;
  &.is-active {
    display: block;
    animation-name: pulse;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border: 1px solid $color-white--100;

    @include reduced-motion {
      animation: none;
    }

    @include media-from(md) {
      animation-iteration-count: 1;
      border: 1.5px solid $color-white--100;
    }
  }

  @include media-from(md) {
    height: 4rem;
    width: 4rem;
  }
}

.inner-pulse.is-active {
  animation-delay: 0.8s;
}

.tooltip {
  width: 17.25rem;
  background-color: $color-surrogate--100;
  color: $color-white--100;
  position: absolute;
  z-index: 3;
  top: 82px; // 64px = height if Bubble, 8px = distance to arrow, 10px = height of arrow
  display: none;
  padding: 1rem;
  border-radius: 3px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.24);
  &.tooltip-far-left {
    top: 6px;
    left: 82px;
  }
  &.tooltip-far-right {
    top: 6px;
    right: 82px;
  }
  &.tooltip-far-down {
    top: initial;
    bottom: 82px;
  }
}

.tooltip-arrow {
  height: 18px;
  width: 18px;
  background-color: $color-surrogate--100;
  transform: rotate(45deg);
  border-radius: 3px;
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  margin: auto;
}

.tooltip-far-left {
  .tooltip-arrow {
    top: 20px;
    left: -8px;
    right: initial;
    margin: 0;
  }
}

.tooltip-far-right {
  .tooltip-arrow {
    top: 20px;
    left: initial;
    right: -8px;
    margin: 0;
  }
}

.tooltip-far-down {
  .tooltip-arrow {
    margin: auto;
    top: initial;
    bottom: -8px;
  }
}

.tooltip-title {
  font-weight: $font-weight-bold;
  font-size: $font-size-18;
  margin-bottom: 0.5rem;
  margin-block-start: 0;
  margin-block-end: 0;
}

.tooltip-text {
  font-weight: $font-weight-regular;
  font-size: $font-size-16;
  line-height: 1.375rem;
  margin-block-start: 0;
  margin-block-end: 0;
}

.is-inverted {
  .tooltip {
    color: $color-white--100;
    background-color: $color-red--100;
  }
  .tooltip-arrow {
    background-color: $color-red--100;
  }
}

.bubble-content {
  background-color: $color-white--100;
  color: $color-surrogate--100;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24);
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.number {
  font-size: $font-size-32;
  font-weight: $font-weight-bold;
}

.is-inverted {
  .bubble-content {
    background-color: $color-red--100;
    color: $color-white--100;
  }
  &.is-active {
    color: $color-white--100;
  }
}

.button-link {
  color: $color-white--100;
  margin: 0.5rem 0 0.25rem;
}

@include media-from(md) {
  .base:hover {
    z-index: 2;
    .tooltip {
      display: block;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}
